import { useContext } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import UAParser from 'ua-parser-js';

export const addCurrency = (price: string | number, noSpace = false): string => {
  const { $novulo } = useVSFContext();
  const wt = $novulo.config.state.getWt();
  const frenchWTs = [20, 25, 27, 29, 42, 43];
  const germanWTs = [21, 24, 30, 28, 35, 57, 60, 61];
  const ukWTs = [37];

  if (ukWTs.includes(wt)) {
    return noSpace ? `£${price}` : `£ ${price}`;
  } else if ([...frenchWTs, ...germanWTs].includes(wt)) {
    return noSpace ? `${price}€` : `${price} €`;
  } else {
    return noSpace ? `€${price}` : `€ ${price}`;
  }
};

export const getCurrencyCode = ({ vsfContext }) => {
  const ukWTs = [37];

  const { $novulo } = vsfContext;
  const wt = $novulo.config.state.getWt();

  if (ukWTs.includes(wt)) {
    return 'GBP';
  } else {
    return 'EUR';
  }
};

export const getBrowserDetails = ({ vsfContext, i18n }) => {
  const domainConfig = vsfContext.$novulo.config.state.getDomainConfig();
  const parser = new UAParser();
  const results = parser.getResult();
  const locale = i18n.locale;
  const country = domainConfig.websiteCountry;
  return {
    browser: results.browser.name,
    device: results.device.model ?? 'Other',
    os: results.os.name,
    location: window.location.href,
    path: window.location.pathname,
    language: locale,
    country: country,
    locale: locale + '_' + country.toUpperCase(),
    domain: window.location.host,
    label: domainConfig.websiteLabel
  };
};

export const calculateInsurancePrice = ({
  itemPrice,
  isNetherlands,
  isGermany,
  isBe
}) => {
  if (isNetherlands) {
    return (itemPrice * 12) / 100;
  } else if (isBe) {
    return (itemPrice * 5) / 100;
  } else if (isGermany) {
    const THRESHOLDS = [
      { max: 200, price: 49 },
      { max: 400, price: 69 },
      { max: 700, price: 99 },
      { max: 1000, price: 119 },
      { max: 1500, price: 149 },
      { max: 2000, price: 189 },
      { max: 3000, price: 249 },
      { max: 4000, price: 349 },
      { max: 5000, price: 399 },
      { max: 7500, price: 499 }
    ];

    for (const { max, price } of THRESHOLDS) {
      if (itemPrice < max) {
        return price;
      }
    }
  }
  return 0;
};

export const formatDate = (date: string) => {
  const { app } = useContext();
  const monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const toFormatDate = new Date(date);
  return (
    app.i18n.t(monthsArray[toFormatDate.getMonth()]) +
    ' ' +
    toFormatDate.getDate() +
    ', ' +
    toFormatDate.getFullYear() +
    ' at ' +
    toFormatDate.getHours() +
    ':' +
    toFormatDate.getMinutes()
  );
};

export const viewportGetter = () => {
  if (process.browser && window.innerWidth) {
    return { width: window.innerWidth, height: window.innerHeight };
  }
  return {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight
  };
};

export const priceFormat = (
  price: number,
  {
    noDash,
    hideFractionIfInt,
    withPoint
  }: { noDash?: boolean; hideFractionIfInt?: boolean, withPoint?: boolean } = {}
) => {
  const { $novulo } = useVSFContext();
  const wt = $novulo.config.state.getWt();
  let locale = 'nl-NL';
  let fractionSeparator = ',';

  if (wt === 31 || wt === 37) {
    locale = 'en-GB';
    fractionSeparator = '.';
  }
  if (typeof price !== 'number') return '';
  const formattedNumber = price.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false
  });
  if (formattedNumber.endsWith(`${fractionSeparator}00`)) {
    if (hideFractionIfInt) {
      return formattedNumber.replace(`${fractionSeparator}00`, '');
    }
    if (noDash) {
      return formattedNumber;
    }
    return formattedNumber.replace(
      `${fractionSeparator}00`,
      `${fractionSeparator}-`
    );
  }

  return formattedNumber;
};

export const addThousandSeparator = (price: number) => {
  const { $novulo } = useVSFContext();
  const wt = $novulo.config.state.getWt();
  if (wt === 31 || wt === 37) {
    return price.toLocaleString('en-GB');
  } else {
    return price.toLocaleString('nl-NL');
  }
};
export const extractIntegerAndFormat = (price: number, { seperateThousand }:{ seperateThousand?: boolean } = {}) => {
  const { $novulo } = useVSFContext();
  const wt = $novulo.config.state.getWt();
  const integer = Math.floor(price);
  let fractionSeparator = ',';
  if (wt === 31 || wt === 37) {
    fractionSeparator = '.';
  }
  const formattedPrice = seperateThousand ? addThousandSeparator(integer) : integer;
  return price === integer
    ? `${formattedPrice}${fractionSeparator}-`
    : `${formattedPrice}${fractionSeparator}`;
};

export const booleanArrayOrMerge = (
  arr: Array<Array<boolean>>
): Array<boolean> => {
  return arr.reduce((acc, val) => {
    val.forEach((bool, ind) => (acc[ind] = acc[ind] || bool));
    return acc;
  }, []);
};

export const mediaUrlFallback = (url: string): string => {
  return process.env.NODE_ENV === 'development'
    ? `https://test-ke-nl.kenovulo.nl${url}`
    : url;
};

export const updateQueryStringParameter = (
  url: URL,
  key: string,
  value: string | number
) => {
  if (url.search.includes(key)) {
    return url.searchParams.set(
      key,
      [...url.searchParams.getAll(key), value].join()
    );
  } else {
    return url.searchParams.append(key, String(value));
  }
};

export const getSearchParamsObject = () => {
  // @ts-ignore
  const url = new URL(window.location);
  const searchParamsObj = {};
  for (const entry of url.searchParams.entries()) {
    searchParamsObj[entry[0]] = entry[1];
  }
  return searchParamsObj;
};

export const getMetaTags = ({
  charset,
  title,
  description,
  image,
  type,
  locale,
  url
}: {
  charset?: string;
  title?: string;
  description: string;
  image?: string;
  type?: string;
  locale: string;
  url: string;
}) => {
  const formattedLocale = locale + '_' + locale.toUpperCase();
  const siteName = 'Kamera Express'; // TODO: make it dynamic for other websites

  let metaTags: any[] = [
    {
      hid: 'og:description',
      property: 'og:description',
      content: description
    },
    {
      hid: 'description',
      name: 'description',
      property: 'description',
      content: description
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: url
    },
    {
      hid: 'og:locale',
      property: 'og:locale',
      content: formattedLocale
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: siteName
    }
  ];

  if (title) {
    metaTags = [
      ...metaTags,
      {
        hid: 'og:title',
        property: 'og:title',
        content: title
      },
      {
        hid: 'title',
        name: 'title',
        property: 'title',
        content: title
      }
    ];
  }

  if (image) {
    metaTags = [
      ...metaTags,
      {
        hid: 'og:image',
        name: 'og:image',
        content: image
      }
    ];
  }

  if (type) {
    metaTags = [
      ...metaTags,
      {
        hid: 'og:type',
        name: 'og:type',
        content: type
      }
    ];
  }

  if (charset) {
    metaTags = [
      ...metaTags,
      {
        charset: charset
      }
    ];
  }

  return metaTags;
};

export enum StatusReference {
  IN_STOCK,
  ON_ORDER,
  NOT_DELIVERABLE,
  PRE_ORDER,
  TEMPORARILY_SOLD_OUT
}

export const getStatusReferenceEnum = (statusReference: number) => {
  const valuesMap = {
    [0]: StatusReference.IN_STOCK,
    [1]: StatusReference.PRE_ORDER,
    [2]: StatusReference.PRE_ORDER,
    [3]: StatusReference.TEMPORARILY_SOLD_OUT,
    [4]: StatusReference.TEMPORARILY_SOLD_OUT,
    [5]: StatusReference.ON_ORDER,
    [6]: StatusReference.NOT_DELIVERABLE,
    [11]: StatusReference.IN_STOCK,
    [12]: StatusReference.IN_STOCK,
    [13]: StatusReference.IN_STOCK,
    [14]: StatusReference.IN_STOCK,
    [100]: StatusReference.IN_STOCK,
    [102]: StatusReference.IN_STOCK
  };

  return valuesMap[statusReference] || StatusReference.IN_STOCK;
};

export const getSSRRequestWebsite = (req: any) => {
  // we assume that the request is always https
  const protocol = 'https';
  // should be always defined, but just in case
  const host = req?.headers?.host || '';
  return `${protocol}://${host}`;
};

export const getSSRRequestFullUrl = (req: any) => {
  const website = getSSRRequestWebsite(req);
  // should be always defined, but just in case
  const url = req?.url || '';
  return `${website}${url}`;
};

type CustomField = {
  key: string;
  value_string?: string;
  value_string_area?: string;
  value_select?: string;
  custom_value_select?: string;
  checkbox?: 0 | 1;
};
export const getCustomFieldByKey = (
  customFields: CustomField[],
  key: string
): CustomField => {
  return customFields?.find((customField) => customField.key === key);
};

export const extractPathFromUrl = (url: string): string => {
  const path = url.split('?')[0];
  let pathNameAndParams = path.split('/').slice(1);
  // naive way to check if there's a language prefix
  if (pathNameAndParams[0].length === 2)
    pathNameAndParams = pathNameAndParams.slice(1);
  const pathName = pathNameAndParams.join('/');
  return pathName;
};

export const getDateDay = ({ dateStr, i18n }) => {
  const locale = i18n.locale;
  const date = new Date(dateStr);
  const day = date.toLocaleDateString(locale, { weekday: 'long' });
  return day;
};

export const getTranslatedWeekday = ({ weekdayIndex, i18n }) => {
  const dummyDate = new Date(2001, 0, weekdayIndex);
  const locale = i18n.locale;
  return dummyDate.toLocaleDateString(locale, { weekday: 'long' });
};

export const pushHistoryState = (url) => {
  const currentUrl = window.location.href;
  window.history.pushState({}, '', url.href);
  if (!(window as any).dataLayer) {
    return;
  }
  const newUrl = window.location.href;
  if (newUrl === currentUrl) {
    return;
  }
  const newPath = window.location.pathname;
  const newTitle = window.document.title;
  (window as any).dataLayer.push({
    event: 'virtual_pageview',
    new_page_url: newUrl,
    new_page_path: newPath,
    new_page_title: newTitle,
    old_url: currentUrl
  });
};

export const buildBloomreachCheckoutEventData = ({
  browserDetails,
  userToken,
  stepNumber,
  stepTitle,
  currency,
  cart = null,
  saleslines = null,
  paymentMethod = undefined
}) => {
  let formattedItems;
  let totalCashback = 0;
  let shippingCost = 0;
  if (saleslines) {
    formattedItems =
      saleslines
        ?.filter(
          (item) => !(item.is_discount_item || item.is_shipping_cost_item)
        )
        ?.map((item) => ({
          item_id: item.product_code,
          brand: item.productbrand,
          category: item.category,
          url: item.url,
          title: item.description,
          item_original_price: item.unit_price,
          item_sale_price: item.unit_price - item.discount / item.quantity,
          discount: item.discount / item.quantity,
          quantity: item.quantity,
          total_price: item.total_price_sl - item.discount,
          total_discount: item.discount
        })) || [];
    totalCashback = saleslines?.reduce(
      (acc, item) =>
        acc +
        Math.max(
          item.total_price_sl -
            item.discount -
            item.price_after_cashback_discount,
          0
        ),
      0
    );
    shippingCost = saleslines?.[0]?.shipping ?? 0;
  } else {
    formattedItems =
      cart?.shoppingcart_items
        ?.filter((item) => !item.is_shipping_costs_item)
        ?.map((item) => ({
          item_id: item.sku,
          brand: item.brand,
          category: item.category,
          url: item.url,
          title: item.name,
          item_original_price: item.price / item.quantity,
          item_sale_price: item.price_after_cash_discount / item.quantity,
          discount: item.discount / item.quantity,
          quantity: item.quantity,
          total_price: item.price,
          total_discount: item.discount
        })) || [];
    totalCashback = cart?.shoppingcart_items?.reduce(
      (acc, item) =>
        acc +
        Math.max(
          item.price_after_cash_discount - item.price_after_cashback_discount,
          0
        ),
      0
    );
    shippingCost =
      cart?.shoppingcart_items?.find((item) => item.is_shipping_costs_item)
        ?.price_after_cash_discount ?? 0;
  }

  const { subtotal, productsDiscount, quantity } = formattedItems.reduce(
    (acc, item) => ({
      subtotal: acc.subtotal + item.total_price,
      productsDiscount: acc.productsDiscount + item.total_discount,
      quantity: acc.quantity + item.quantity
    }),
    {
      subtotal: 0,
      productsDiscount: 0,
      quantity: 0
    }
  );

  const giftcardsCodes =
    cart?.gift_cards?.map((item) => item.code)?.filter((code) => code) ?? [];
  const giftCardsValue =
    cart?.gift_cards?.reduce((acc, item) => acc + item.value, 0) || 0;

  const couponCodes =
    cart?.couponcodes
      ?.map((item) => item.code || item.related_to_product)
      ?.filter((code) => code) ?? [];
  const couponsValue =
    cart?.couponcodes?.reduce((acc, item) => acc + item.value, 0) || 0;

  const totalDiscount = productsDiscount + giftCardsValue + couponsValue;

  const totalPrice = subtotal + shippingCost - totalDiscount;

  // TODO: add trade_in data
  return {
    ...browserDetails,
    type: userToken ? 'identified' : 'guest',
    step_number: stepNumber,
    step_title: stepTitle,
    payment_method: paymentMethod,
    shipping_cost: shippingCost,
    subtotal_price: subtotal,
    gift_card_code: giftcardsCodes,
    gift_card_value: giftCardsValue,
    voucher_code: couponCodes,
    voucher_value: couponsValue,
    total_cashback_value: totalCashback > 0 ? totalCashback : undefined,
    total_trade_in_value: undefined,
    total_quantity: quantity,
    total_price: totalPrice,
    total_discount_value: totalDiscount,
    currency,
    item_ids: formattedItems.map((item) => ({
      item_id: item.item_id,
      qty: item.quantity
    })),
    item_details: formattedItems
  };
};

export const capitalize = (str) => str ? str[0].toUpperCase() + str.slice(1) : '';
