import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import { useCurrentUser } from 'src/useCurrentUser';
import { useKECart } from 'src/useKECart';
import { useBloomreachEvents } from '../useBloomreachEvents';
import { createBloomreachCartUpdateEventDetails } from '../shared';
import { computed } from '@nuxtjs/composition-api';
import type { UseCheckoutCart } from './interfaces';

const CART_COOKIE = 'n_shopping_cart';
export const useCheckoutCart = (): UseCheckoutCart => {
  const context = useVSFContext();
  const { currentUser, getUserToken } = useCurrentUser();
  const { cartItems, totalPrice, setCartCount } = useKECart();
  const { registerEvent } = useBloomreachEvents();

  const cart = sharedRef({}, 'useCheckoutCart-cart');
  let cartId = context.$cookies.get(CART_COOKIE) || null;
  const isLoading = sharedRef(
    {
      getCart: false,
      updateItemQuantity: false,
      toggleInsurance: false,
      removeAllGiftcards: false,
      getShippingRequests: true,
      setShippingOption: false
    },
    'useCheckoutCart-isLoading'
  );
  const setCartId = (cart_guid) => {
    if (cart_guid) {
      context.$cookies.set(CART_COOKIE, cart_guid, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30 * 6
      });
    } else {
      context.$cookies.remove(CART_COOKIE);
    }
    cartId = cart_guid;
  };

  const getCart = async () => {
    isLoading.value.getCart = true;
    const userToken = getUserToken();

    if (cartId)
      await context.$novulo.api.validateCart(cartId).catch(() => {
        setCartId(null);
      });

    if (!cartId && !userToken) {
      isLoading.value.getCart = false;
      return;
    }

    try {
      const response = await context.$novulo.api.getKECart({
        cartId,
        userToken,
        showAll: true
      });
      cart.value = response || {};

      cartItems.value = response.shoppingcart_items;
      totalPrice.value = response.total_price_after_discount;
      const itemsCount = cartItems.value.reduce(
        (acc, item) => acc + item.quantity,
        0
      );

      setCartCount(itemsCount);
      setCartId(response.shoppingcart_guid);
    } catch (err) {
      Logger.error('useCheckoutCart/getCart', err);
      setCartId(null);
    }
    isLoading.value.getCart = false;
  };

  const registerBloomreachEvent = ({
    cartItem,
    oldQuantity,
    newQuantity,
    currency,
    browserDetails
  }) => {
    if (!cartItem) return;
    registerEvent(
      'cart_update',
      createBloomreachCartUpdateEventDetails({
        cartId,
        cartItems: cartItems.value,
        cartItem,
        oldQuantity,
        newQuantity,
        currency,
        browserDetails
      })
    );
  };

  const updateItemQuantity = async ({
    orderLine,
    newQuantity,
    currency,
    browserDetails
  }) => {
    isLoading.value.updateItemQuantity = true;
    try {
      const cartItem =
        cart.value.shoppingcart_items?.find(
          (item) => item.id === orderLine.id
        ) ?? null;
      const oldQuantity = cartItem.quantity;
      const { error, success, response } =
        await context.$novulo.api.updateKECart({
          shopping_cart_id: cart.value.id,
          cartId,
          spliId: orderLine.spliId,
          newQuantity,
          userId: currentUser.value?.id,
          userToken: getUserToken(),
          cartItemId: orderLine.id
        });
      if (error || !success || !response || !response.cart_guid) {
        context.$cookies.remove(CART_COOKIE);
        throw new Error('Error updating cart');
      } else {
        setCartId(response.cart_guid);
      }
      await getCart();
      registerBloomreachEvent({
        cartItem,
        oldQuantity,
        newQuantity,
        currency,
        browserDetails
      });
    } catch (err) {
      Logger.error('useCheckoutCart/updateItemQuantity', err);
    }
    isLoading.value.updateItemQuantity = false;
  };

  const paymentMethods = sharedRef([], 'useCheckoutCart-paymentMethods');
  const getPaymentMethods = async () => {
    paymentMethods.value = await context.$novulo.api.getPaymentMethods();
  };

  const toggleInsurance = async ({
    cartItemId,
    isCurrentlyActive,
    currency,
    browserDetails
  }) => {
    try {
      isLoading.value.toggleInsurance = true;
      let insuranceItem;
      if (isCurrentlyActive) {
        insuranceItem = cart.value.shoppingcart_items.find(
          (item) => item.is_insurance_item
        );
      }
      await context.$novulo.api.toggleInsurance({
        cartItemId,
        isCurrentlyActive
      });
      await getCart();
      if (!isCurrentlyActive) {
        insuranceItem = cart.value.shoppingcart_items.find(
          (item) => item.is_insurance_item
        );
      }
      registerBloomreachEvent({
        cartItem: insuranceItem,
        oldQuantity: isCurrentlyActive ? 1 : 0,
        newQuantity: isCurrentlyActive ? 0 : 1,
        currency,
        browserDetails
      });
    } catch (err) {
      Logger.error('useCheckoutCart/toggleInsurance', err);
    } finally {
      isLoading.value.toggleInsurance = false;
    }
  };

  const setPaymentMethod = (code) => {
    return context.$novulo.api.setPaymentMethod({ cartId, methodCode: code });
  };

  const determineValueAfterGiftcards = () => {
    return context.$novulo.api.determineValueAfterGiftcards({
      cartId
    });
  };

  const shippingRequests = sharedRef(
    { shipping: [], stores: [], pickUp: [] },
    'useCheckoutCart-shippingRequests'
  );
  const getShippingRequests = async () => {
    if (cart.value?.is_for_external_dropshipment_only) return;

    try {
      isLoading.value.getShippingRequests = true;
      const response = await context.$novulo.api.getShippingRequests({
        cartId
      });
      shippingRequests.value = response || {};
    } catch (err) {
      Logger.error('useCheckoutCart/getShippingRequests', err);
    } finally {
      isLoading.value.getShippingRequests = false;
    }
  };

  const setShippingOption = async (methodId: string) => {
    isLoading.value.setShippingOption = true;
    try {
      await context.$novulo.api.setShippingOption({
        cartId,
        methodId
      });
      await determineValueAfterGiftcards();
      void getCart();
    } catch (e) {
      Logger.error('useCheckoutCart/setShippingOption', e);
    } finally {
      isLoading.value.setShippingOption = false;
    }
  };

  const removeAllGiftcards = async () => {
    const references =
      cart.value.gift_cards?.map((card) => card.reference) || [];
    if (!references.length) return;
    try {
      isLoading.value.removeAllGiftcards = true;
      await Promise.all(
        references.map((reference) =>
          context.$novulo.api.removeDiscount({
            code: reference,
            type: 'gift_card',
            cart_guid: cartId
          })
        )
      );
      await Promise.all([getCart(), determineValueAfterGiftcards()]);
    } catch (err) {
      Logger.error('useCheckoutCart/removeAllGiftcards', err);
    } finally {
      isLoading.value.removeAllGiftcards = false;
    }
  };

  const getProceedToPSPData = async ({ newsletterSubscriptionForm }) => {
    return context.$novulo.api
      .proceedToPSP({ cartId, newsletterSubscriptionForm })
      .catch((err) => {
        Logger.error('useCheckoutCart/proceedToPSP', err);
        throw err;
      });
  };

  const hasDiscontinuedProducts = computed(() =>
    cart.value.orderLines?.some(
      (orderLine) => orderLine.statusData.statusReference === 6
    )
  );

  const setPaymentAnalytics = (params) => {
    const domainConfig = context.$novulo.config.state.domainConfig;
    return context.$novulo.api.setPaymentAnalytics({ ...params, domainConfig });
  };

  return {
    cartId,
    cart: computed(() => cart.value),
    hasDiscontinuedProducts,
    isLoading: computed(() => isLoading.value),
    getCart,
    updateItemQuantity,
    paymentMethods: computed(() => paymentMethods.value),
    getPaymentMethods,
    setPaymentMethod,
    determineValueAfterGiftcards,
    toggleInsurance,
    shippingRequests: computed(() => shippingRequests.value),
    getShippingRequests,
    setShippingOption,
    removeAllGiftcards,
    setPaymentAnalytics,
    getProceedToPSPData
  };
};
