import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';

// TODO: Adjust the params type
interface PLPEssentialsParams {
  id?: number;
  params?: any;
}
// TODO: Adjust the types based on the actual API response
interface PLPEssentialsResult {
  [key: string]: any;
}
interface UsePLPEssentialsReturn {
  getPLPEssentials: (params: PLPEssentialsParams) => Promise<void>;
  PLPEssentialsResult: any;
  PLPEssentialsLoading: any;
  PLPEssentialsError: any;
}

export const usePLPEssentials = (): UsePLPEssentialsReturn => {
  const context = useVSFContext();
  const result = sharedRef<PLPEssentialsResult | null>(null, 'usePLPEssentials');
  const loading = sharedRef<boolean>(false, 'usePLPEssentials-loading');
  const error = sharedRef<{ getPLPEssentials: any | null }>(
    { getPLPEssentials: null },
    'usePLPEssentials-error'
  );

  const getPLPEssentials = async (params: PLPEssentialsParams): Promise<void> => {
    Logger.debug('usePLPEssentials custom componsable');
    try {
      loading.value = true;
      result.value = await context.$novulo.api.getPLPEssentials(params);
    } catch (err) {
      error.value.getPLPEssentials = err;
      Logger.error('usePLPEssentials', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    getPLPEssentials,
    PLPEssentialsResult: computed(() => result.value),
    PLPEssentialsLoading: computed(() => loading.value),
    PLPEssentialsError: computed(() => error.value)
  };
};
